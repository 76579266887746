import Logo from "./img/Logo";
import "./styles.scss";
import { useState } from "react";
import NumberOne from "./img/NumberOne";
import NumberThree from "./img/NumberThree";
import NumberSix from "./img/NumberSix";
import SelectedNumberOne from "./img/SelectedNumberOne";
import SelectedNumberThree from "./img/SelectedNumberThree";
import SelectedNumberSix from "./img/SelectedNumberSix";
import { useParams, useLocation } from "react-router-dom";
import { post } from "./api/request";
import { Oval } from "react-loader-spinner";
import BigLogo from "./img/BigLogo";
import { useEffect } from "react";

const App = () => {
  //payment, successPayment, errorPayment
  const [pageState, setPageState] = useState("payment");

  const [currentCard, setCurrentCard] = useState(1);
  const [isLoading, setIsLoading] = useState(null);

  const { pathname } = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const lastValue = urlParams.get("id");

  useEffect(() => {
    setPageState(urlParams.get("pageState") || "payment");
  });

  const sendToBack = () => {
    setIsLoading(true);

    const data = {
      urlSuccess: `${process.env.REACT_APP_API_URL}payments?id=${lastValue}&pageState=successPayment`,
      urlReturn: `${process.env.REACT_APP_API_URL}payments?id=${lastValue}&pageState=errorPayment`,
      subscriptionTypeId: currentCard
    };

    post(
      `subscriptions/user/${lastValue}`,
      data
    )
      .then((response) => {
        window.location = response.data.paymentLink
      })
      .catch((e) => {
        setPageState("errorPayment");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const cards = [
    {
      id: 1,
      price: "2 000",
      month: 1,
    },
    {
      id: 2,
      price: "5 100",
      month: 3,
    },
    {
      id: 3,
      price: "9 600",
      month: 6,
    },
  ];

  const renderPayment = () => {
    return (
      <>
        <div className="logo">
          <Logo />
        </div>

        <div className="title">Управление подпиской</div>
        <div className="desc">
          Выберите период для продления. Чем больше срок, тем больше выгода.
        </div>
        <div className="cards">
          {cards.map((item) => (
            <div
              className={`cards-item ${
                currentCard === item.id && "selected-card"
              }`}
              onClick={() => setCurrentCard(item.id)}
            >
              <div
                className={`cards-item-month ${
                  currentCard === item.id && "selected-card-month"
                }`}
              >
                {item.month} месяц
              </div>
              <div
                className={`cards-item-price ${
                  currentCard === item.id && "selected-card-price"
                }`}
              >
                {item.price} р.
              </div>
              <div
                className="cards-item-number"
                style={{
                  marginRight: item.id === 1 ? 20 : 0,
                }}
              >
                {item.month === 1 && <NumberOne />}
                {item.month === 1 && currentCard === item.id && (
                  <SelectedNumberOne />
                )}
                {item.month === 3 && <NumberThree />}
                {item.month === 3 && currentCard === item.id && (
                  <SelectedNumberThree />
                )}
                {item.month === 6 && <NumberSix />}
                {item.month === 6 && currentCard === item.id && (
                  <SelectedNumberSix />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="help">1 месяц равен 30 календарным дням</div>

        <button
          onClick={sendToBack}
          className="button"
          disabled={!currentCard || isLoading}
        >
          Оплатить
          {isLoading && (
            <div className="button-loader">
              <Oval
                strokeWidth={5}
                strokeWidthSecondary={5}
                secondaryColor={"rgba(255,255,255,0.6)"}
                height="20"
                width="20"
                color="#fff"
              />
            </div>
          )}
        </button>
      </>
    );
  };

  const successPayment = () => {
    return (
      <div className="success-payment-wrapper">
        <div className="success-payment-wrapper-logo">
          <BigLogo />
        </div>
        <div className="success-payment-wrapper-title">
          Оплата прошла успешно
        </div>
        <div className="success-payment-wrapper-desc">
          Подписка активируется в течении нескольких минут.
        </div>
      </div>
    );
  };
  const errorPayment = () => {
    return (
      <div className="error-payment-wrapper">
        <div className="error-payment-wrapper-logo">
          <BigLogo />
        </div>
        <div className="error-payment-wrapper-title">Оплата не прошла</div>
        <div className="error-payment-wrapper-desc">
          При обработке платежа произошла ошибка.
        </div>
      </div>
    );
  };

  return (
    <div className="wrapper">
      {pageState === "payment" && renderPayment()}

      {pageState === "successPayment" && successPayment()}

      {pageState === "errorPayment" && errorPayment()}
    </div>
  );
};

export default App;
