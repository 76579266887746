import React from 'react';

const NumberOne = () => {
    return (
        <svg width="32" height="66" viewBox="0 0 32 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6082 66V5.616L20.7682 13.488H0.128246V-1.2H31.6162V66H12.6082Z" fill="#619B09"
                  fillOpacity="0.2"/>
        </svg>

    );
};

export default NumberOne;