import React from 'react';

const NumberSix = () => {
    return (
        <svg width="58" height="68" viewBox="0 0 58 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M31.2015 67.344C24.9295 67.344 19.4575 66.096 14.7855 63.6C10.1775 61.104 6.5615 57.392 3.9375 52.464C1.3775 47.536 0.0974963 41.456 0.0974963 34.224C0.0974963 26.416 1.6015 19.792 4.6095 14.352C7.6815 8.848 11.8735 4.656 17.1855 1.776C22.5615 -1.104 28.7695 -2.544 35.8095 -2.544C39.5855 -2.544 43.2015 -2.096 46.6575 -1.2C50.1775 -0.368004 53.1535 0.815996 55.5855 2.352L48.6735 15.984C46.7535 14.64 44.7375 13.744 42.6255 13.296C40.5775 12.784 38.4655 12.528 36.2895 12.528C31.1055 12.528 26.9455 14.096 23.8095 17.232C20.7375 20.368 19.2015 24.944 19.2015 30.96C19.2015 32.048 19.2015 33.2 19.2015 34.416C19.2655 35.568 19.3935 36.752 19.5855 37.968L14.7855 34.032C16.0655 31.664 17.6015 29.68 19.3935 28.08C21.2495 26.48 23.4255 25.264 25.9215 24.432C28.4815 23.6 31.3295 23.184 34.4655 23.184C38.6895 23.184 42.5295 24.08 45.9855 25.872C49.5055 27.6 52.3215 30.064 54.4335 33.264C56.5455 36.4 57.6015 40.112 57.6015 44.4C57.6015 49.072 56.4175 53.136 54.0495 56.592C51.6815 59.984 48.5135 62.64 44.5455 64.56C40.5775 66.416 36.1295 67.344 31.2015 67.344ZM30.0495 53.808C31.8415 53.808 33.4095 53.456 34.7535 52.752C36.1615 52.048 37.2495 51.056 38.0175 49.776C38.7855 48.496 39.1695 46.992 39.1695 45.264C39.1695 42.704 38.3055 40.656 36.5775 39.12C34.9135 37.584 32.7055 36.816 29.9535 36.816C28.0975 36.816 26.4655 37.168 25.0575 37.872C23.7135 38.576 22.6255 39.568 21.7935 40.848C21.0255 42.128 20.6415 43.632 20.6415 45.36C20.6415 47.024 21.0255 48.496 21.7935 49.776C22.5615 50.992 23.6495 51.984 25.0575 52.752C26.4655 53.456 28.1295 53.808 30.0495 53.808Z"
                fill="#619B09" fillOpacity="0.2"/>
        </svg>
    );
};

export default NumberSix;