import React from 'react';

const SelectedNumberThree = () => {
    return (
        <svg width="57" height="68" viewBox="0 0 57 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.8115 67.344C22.1395 67.344 17.4675 66.768 12.7955 65.616C8.1875 64.464 4.1555 62.832 0.699496 60.72L7.6115 46.416C10.2995 48.208 13.2755 49.584 16.5395 50.544C19.8675 51.504 23.0995 51.984 26.2355 51.984C29.4995 51.984 32.0915 51.376 34.0115 50.16C35.9315 48.944 36.8915 47.216 36.8915 44.976C36.8915 42.992 36.0915 41.424 34.4915 40.272C32.8915 39.056 30.2675 38.448 26.6195 38.448H18.6515V26.448L36.6995 6.96L38.5235 13.488H4.1555V-1.2H52.7315V10.704L34.6835 30.192L25.3715 24.912H30.5555C38.9395 24.912 45.2755 26.8 49.5635 30.576C53.9155 34.288 56.0915 39.088 56.0915 44.976C56.0915 48.816 55.0355 52.432 52.9235 55.824C50.8755 59.216 47.7075 62 43.4195 64.176C39.1315 66.288 33.5955 67.344 26.8115 67.344Z"
                fill="white"
                fillOpacity="0.4"/>
        </svg>

    );
};

export default SelectedNumberThree;