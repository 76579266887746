import {getTransport} from "./transport";

export const get = (path, config) => getTransport()
	.get(`/api/${path}`, config).then((response) => response.data);

export const post = async (path, payload, config) => getTransport()
	.post(`/api/${path}`, payload, config)
	.then((response) => response.data);

export const put = (path, payload = {}) => getTransport()
	.put(`/api/${path}`, payload)
	.then((response) => response.data);

export const patch = (path, payload = {}) => getTransport()
	.patch(`/api/${path}`, payload)
	.then((response) => response.data);

export const httpDelete = (path, config) => getTransport()
	.delete(`/api/${path}`, config)
	.then((response) => response.data);
